// import Seo from "components/seo";
import Layout from "components/layout";
import { graphql } from "gatsby";
import React from "react";
import PageTitle from "components/PageTitle"

function Legals({ data }) {
    const legal = data.wpLegal;
    // const { seo } = post;
    return (
        <>
            {/* <Seo title={seo.title} description={seo.metaDesc} /> */}
            <Layout>
                <section>

                    <PageTitle title={legal.title}/>
                    
                    <div
                        dangerouslySetInnerHTML={{ __html: legal.content }}
                    />
                    
                </section>
            </Layout>
        </>
    );
}

export const query = graphql`
    query($id: String) {
        wpLegal(id: { eq: $id }) {
            title
            content
            slug
            databaseId
            id
        }
    }
`;

export default Legals;
